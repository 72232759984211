var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    [
      _c(
        "a-card",
        { attrs: { title: _vm.$t("lbl_ar_receipt_report") } },
        [
          _c(
            "a-form-model",
            {
              ref: "form",
              attrs: {
                model: _vm.formModel,
                rules: _vm.formRules,
                "wrapper-col": { span: 12 },
                "label-col": { span: 12 }
              },
              nativeOn: {
                submit: function($event) {
                  $event.preventDefault()
                  return _vm.validateForm.apply(null, arguments)
                }
              }
            },
            [
              _c(
                "a-row",
                { attrs: { gutter: [16, 16] } },
                [
                  _c(
                    "a-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: _vm.$t("lbl_branch"),
                            "label-align": "left",
                            prop: "branchId"
                          }
                        },
                        [
                          _c("branch-select", {
                            model: {
                              value: _vm.formModel.branchId,
                              callback: function($$v) {
                                _vm.$set(_vm.formModel, "branchId", $$v)
                              },
                              expression: "formModel.branchId"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: _vm.$t("lbl_receipt_type"),
                            "label-align": "left",
                            prop: "receiptType"
                          }
                        },
                        [
                          _c(
                            "a-select",
                            {
                              attrs: {
                                "allow-clear": "",
                                "dropdown-match-select-width": false
                              },
                              model: {
                                value: _vm.formModel.receiptType,
                                callback: function($$v) {
                                  _vm.$set(_vm.formModel, "receiptType", $$v)
                                },
                                expression: "formModel.receiptType"
                              }
                            },
                            _vm._l(_vm.optReceiptType, function(item) {
                              return _c(
                                "a-select-option",
                                { key: item.key, attrs: { value: item.value } },
                                [_vm._v(" " + _vm._s(item.label) + " ")]
                              )
                            }),
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: _vm.$t("lbl_pph_status"),
                            "label-align": "left",
                            prop: "pphStatus"
                          }
                        },
                        [
                          _c(
                            "a-select",
                            {
                              attrs: {
                                "allow-clear": "",
                                "dropdown-match-select-width": false
                              },
                              model: {
                                value: _vm.formModel.pphStatus,
                                callback: function($$v) {
                                  _vm.$set(_vm.formModel, "pphStatus", $$v)
                                },
                                expression: "formModel.pphStatus"
                              }
                            },
                            _vm._l(_vm.optPphStatus, function(item) {
                              return _c(
                                "a-select-option",
                                { key: item.key, attrs: { value: item.value } },
                                [_vm._v(" " + _vm._s(item.label) + " ")]
                              )
                            }),
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: _vm.$t("lbl_pph_number"),
                            prop: "pphNo",
                            "label-align": "left"
                          }
                        },
                        [
                          _c(
                            "a-select",
                            {
                              attrs: {
                                "allow-clear": "",
                                "show-search": "",
                                "dropdown-match-select-width": false,
                                loading: _vm.loading.pphNo,
                                "filter-option": false
                              },
                              on: { search: _vm.onSearchPphNo },
                              model: {
                                value: _vm.formModel.pphNo,
                                callback: function($$v) {
                                  _vm.$set(_vm.formModel, "pphNo", $$v)
                                },
                                expression: "formModel.pphNo"
                              }
                            },
                            _vm._l(_vm.optPphNumber, function(item) {
                              return _c(
                                "a-select-option",
                                { key: item.key, attrs: { value: item.value } },
                                [
                                  _c(
                                    "a-tooltip",
                                    { attrs: { title: item.label } },
                                    [_vm._v(" " + _vm._s(item.label) + " ")]
                                  )
                                ],
                                1
                              )
                            }),
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: _vm.$t("lbl_customer"),
                            "label-align": "left",
                            prop: "customerId"
                          }
                        },
                        [
                          _c(
                            "a-select",
                            {
                              attrs: {
                                "allow-clear": "",
                                "show-search": "",
                                "dropdown-match-select-width": false,
                                loading: _vm.loading.customer,
                                "filter-option": false
                              },
                              on: { search: _vm.onSearchCustomer },
                              model: {
                                value: _vm.formModel.customerId,
                                callback: function($$v) {
                                  _vm.$set(_vm.formModel, "customerId", $$v)
                                },
                                expression: "formModel.customerId"
                              }
                            },
                            _vm._l(_vm.optCustomer, function(item) {
                              return _c(
                                "a-select-option",
                                { key: item.key, attrs: { value: item.value } },
                                [
                                  _c(
                                    "a-tooltip",
                                    { attrs: { title: item.label } },
                                    [_vm._v(" " + _vm._s(item.label) + " ")]
                                  )
                                ],
                                1
                              )
                            }),
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: _vm.$t("lbl_ar_receipt_number"),
                            prop: "arReceiptNo"
                          }
                        },
                        [
                          _c(
                            "a-select",
                            {
                              attrs: {
                                "allow-clear": "",
                                "show-search": "",
                                "dropdown-match-select-width": false,
                                "filter-option": false,
                                loading: _vm.loading.arReceipt
                              },
                              on: { search: _vm.onSearchReceiptAr },
                              model: {
                                value: _vm.formModel.arReceiptNo,
                                callback: function($$v) {
                                  _vm.$set(_vm.formModel, "arReceiptNo", $$v)
                                },
                                expression: "formModel.arReceiptNo"
                              }
                            },
                            _vm._l(_vm.optReceiptAr, function(item) {
                              return _c(
                                "a-select-option",
                                { key: item.key, attrs: { value: item.value } },
                                [
                                  _c(
                                    "a-tooltip",
                                    { attrs: { title: item.label } },
                                    [_vm._v(" " + _vm._s(item.label) + " ")]
                                  )
                                ],
                                1
                              )
                            }),
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: _vm.$t("lbl_invoice_source"),
                            prop: "invoiceSource"
                          }
                        },
                        [
                          _c("SelectMasterType", {
                            attrs: { name: "INVOICE_SOURCE" },
                            model: {
                              value: _vm.formModel.invoiceSource,
                              callback: function($$v) {
                                _vm.$set(_vm.formModel, "invoiceSource", $$v)
                              },
                              expression: "formModel.invoiceSource"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: _vm.$t("lbl_ar_invoice_number"),
                            prop: "invoiceNumber"
                          }
                        },
                        [
                          _c("a-input", {
                            attrs: { "allow-clear": "" },
                            model: {
                              value: _vm.formModel.invoiceNumber,
                              callback: function($$v) {
                                _vm.$set(_vm.formModel, "invoiceNumber", $$v)
                              },
                              expression: "formModel.invoiceNumber"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: _vm.$t("lbl_invoice_ar_status"),
                            prop: "paymentStatus"
                          }
                        },
                        [
                          _c(
                            "a-select",
                            {
                              attrs: {
                                "allow-clear": "",
                                mode: "multiple",
                                "dropdown-match-select-width": false
                              },
                              model: {
                                value: _vm.formModel.paymentStatus,
                                callback: function($$v) {
                                  _vm.$set(_vm.formModel, "paymentStatus", $$v)
                                },
                                expression: "formModel.paymentStatus"
                              }
                            },
                            _vm._l(_vm.optPaymentStatus, function(item) {
                              return _c(
                                "a-select-option",
                                { key: item.key, attrs: { value: item.value } },
                                [_vm._v(" " + _vm._s(item.label) + " ")]
                              )
                            }),
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: _vm.$t("lbl_invoice_type"),
                            prop: "invoiceType"
                          }
                        },
                        [
                          _c(
                            "a-select",
                            {
                              attrs: {
                                "allow-clear": "",
                                "dropdown-match-select-width": false
                              },
                              model: {
                                value: _vm.formModel.invoiceType,
                                callback: function($$v) {
                                  _vm.$set(_vm.formModel, "invoiceType", $$v)
                                },
                                expression: "formModel.invoiceType"
                              }
                            },
                            _vm._l(_vm.optInvoiceType, function(item) {
                              return _c(
                                "a-select-option",
                                { key: item.key, attrs: { value: item.value } },
                                [_vm._v(" " + _vm._s(item.label) + " ")]
                              )
                            }),
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: _vm.$t("lbl_ar_invoice_date_from"),
                            prop: "periodFrom"
                          }
                        },
                        [
                          _c("a-date-picker", {
                            attrs: { format: _vm.DEFAULT_DATE_FORMAT },
                            model: {
                              value: _vm.formModel.periodFrom,
                              callback: function($$v) {
                                _vm.$set(_vm.formModel, "periodFrom", $$v)
                              },
                              expression: "formModel.periodFrom"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: _vm.$t("lbl_ar_invoice_date_to"),
                            prop: "periodTo"
                          }
                        },
                        [
                          _c("a-date-picker", {
                            attrs: { format: _vm.DEFAULT_DATE_FORMAT },
                            model: {
                              value: _vm.formModel.periodTo,
                              callback: function($$v) {
                                _vm.$set(_vm.formModel, "periodTo", $$v)
                              },
                              expression: "formModel.periodTo"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: _vm.$t("lbl_ar_receipt_date_from"),
                            prop: "arReceiptDateFrom"
                          }
                        },
                        [
                          _c("a-date-picker", {
                            attrs: { format: _vm.DEFAULT_DATE_FORMAT },
                            model: {
                              value: _vm.formModel.arReceiptDateFrom,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.formModel,
                                  "arReceiptDateFrom",
                                  $$v
                                )
                              },
                              expression: "formModel.arReceiptDateFrom"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: _vm.$t("lbl_ar_receipt_date_to"),
                            prop: "arReceiptDateTo"
                          }
                        },
                        [
                          _c("a-date-picker", {
                            attrs: { format: _vm.DEFAULT_DATE_FORMAT },
                            model: {
                              value: _vm.formModel.arReceiptDateTo,
                              callback: function($$v) {
                                _vm.$set(_vm.formModel, "arReceiptDateTo", $$v)
                              },
                              expression: "formModel.arReceiptDateTo"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-row",
                { attrs: { gutter: [16, 16] } },
                [
                  _c(
                    "a-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "a-space",
                        [
                          _c(
                            "a-button",
                            {
                              attrs: { "html-type": "button" },
                              on: { click: _vm.resetField }
                            },
                            [_vm._v(" " + _vm._s(_vm.$t("lbl_reset")) + " ")]
                          ),
                          _c(
                            "a-button",
                            {
                              attrs: {
                                type: "primary",
                                loading: _vm.loading.find,
                                "html-type": "submit"
                              }
                            },
                            [_vm._v(" " + _vm._s(_vm.$t("lbl_find")) + " ")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c("a-table", {
            attrs: {
              "data-source": _vm.dataReport.detail,
              columns: _vm.columns,
              loading: _vm.loading.find,
              pagination: {
                showTotal: function(total) {
                  return _vm.$t("lbl_total_items", { total: total })
                },
                showSizeChanger: true,
                pageSizeOptions: _vm.PAGE_SIZE_OPTIONS
              },
              size: "small",
              scroll: { x: "calc(3000px + 100%)" },
              "row-key": function(r, i) {
                return i
              }
            },
            scopedSlots: _vm._u([
              {
                key: "currency",
                fn: function(text) {
                  return _c("span", {}, [
                    _vm._v(_vm._s(_vm._f("currency")(text)))
                  ])
                }
              },
              {
                key: "number",
                fn: function(text) {
                  return _c("span", {}, [_vm._v(_vm._s(_vm._f("qty")(text)))])
                }
              },
              {
                key: "date",
                fn: function(text) {
                  return _c("span", {}, [_vm._v(_vm._s(_vm._f("date")(text)))])
                }
              },
              {
                key: "nullable",
                fn: function(text) {
                  return _c("span", {}, [_vm._v(_vm._s(text || "-"))])
                }
              },
              {
                key: "footer",
                fn: function() {
                  return [
                    _c(
                      "a-descriptions",
                      [
                        _c(
                          "a-descriptions-item",
                          { attrs: { label: _vm.$t("lbl_total_dpp") } },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm._f("currency")(_vm.dataReport.totalDpp)
                                ) +
                                " "
                            )
                          ]
                        ),
                        _c(
                          "a-descriptions-item",
                          { attrs: { label: _vm.$t("lbl_total_ppn") } },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm._f("currency")(_vm.dataReport.totalPpn)
                                ) +
                                " "
                            )
                          ]
                        ),
                        _c(
                          "a-descriptions-item",
                          { attrs: { label: _vm.$t("lbl_total_amount") } },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm._f("currency")(_vm.dataReport.totalAmount)
                                ) +
                                " "
                            )
                          ]
                        )
                      ],
                      1
                    )
                  ]
                },
                proxy: true
              }
            ])
          }),
          _c(
            "div",
            { staticClass: "text-right mt-2" },
            [
              _c(
                "a-button",
                {
                  attrs: { loading: _vm.loading.download, type: "primary" },
                  on: { click: _vm.download }
                },
                [_vm._v(" " + _vm._s(_vm.$t("lbl_download")) + " ")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }